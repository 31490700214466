import { ActionTree } from 'vuex';
import { State } from '../state';
import { ActionAugments, instance } from '../actions';
import { h } from 'vue';

export interface OverviewActions {
    ['getAllCredentials'](context: ActionAugments): Promise<void>;
    ['getAllOAuth'](context: ActionAugments): Promise<void>;
    ['startOAuth'](context: ActionAugments, data: Record<string, string>): Promise<void>;
    ['updateOAuth'](context: ActionAugments, data: Record<string, string>): Promise<void>;
    ['removeOAuth'](context: ActionAugments, oauth: string): Promise<void>;
    ['saveCredential'](context: ActionAugments, payload: Record<string, string>): Promise<void>;
    ['editCredential'](context: ActionAugments, payload: Record<string, string>): Promise<void>;
    ['removeCredential'](context: ActionAugments, credential: string): Promise<void>;
    ['testCredential'](context: ActionAugments, payload: Record<string, string>): Promise<Record<string, any>>;
    ['getAllFlows'](context: ActionAugments): Promise<void>;
    ['getAllManualStartedLogs'](context: ActionAugments): Promise<void>;
    ['getManualStartedLogsThisMonth'](context: ActionAugments): Promise<void>;
    ['runManualFlow'](context: ActionAugments, payload: Record<string, any>): Promise<void>;
    ['removeFlow'](context: ActionAugments, flow: string): Promise<void>;
    ['duplicateFlow'](context: ActionAugments, flow: Record<string, any>): Promise<void>;
    ['saveSchedule'](context: ActionAugments, schedule: Record<string, any>): Promise<string>;
    ['removeSchedule'](context: ActionAugments, schedule: string): Promise<void>;
    ['getStatisticsForMonth'](context: ActionAugments, Date: Record<string, any>): Promise<void>;
    ['getLogs'](context: ActionAugments, obj: Record<string, any>): Promise<Record<string, any>>;
    ['getSubscription'](context: ActionAugments): Promise<void>;
    ['getAllEmails'](context: ActionAugments): Promise<Array<string> | boolean>;
    ['saveEmail'](context: ActionAugments, payload: Record<string, string>): Promise<void>;
    ['verifyEmail'](context: ActionAugments, payload: Record<string, string>): Promise<void>;
    ['removeEmail'](context: ActionAugments, email: string): Promise<void>;
    ['getAllEndpoints'](context: ActionAugments): Promise<void>;
    ['requestEndpoint'](context: ActionAugments, flow: string): Promise<Record<string, any>>;
    ['removeEndpoint'](context: ActionAugments, flow: string): Promise<void>;
}

export const overviewActions: ActionTree<State, State> & OverviewActions = {
    ['getAllCredentials']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'credentials'
            }).then((res) => {
                commit('setAllCredentials', res.data);
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getAllOAuth']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'auth/external'
            }).then((res) => {
                commit('setAllOAuth', res.data);
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['startOAuth']({ commit }, data: Record<string, string>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `auth/external`,
                data
            }).then((response) => {
                window.location.href = response.data;
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'OAuth starten mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['updateOAuth']({ commit }, data: Record<string, string>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'PUT',
                url: `auth/external`,
                data
            }).then((response) => {
                window.location.href = response.data;
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'OAuth opnieuw starten mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['removeOAuth']({ commit }, oauth: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'DELETE',
                url: `auth/external/${oauth}`
            }).then(() => {
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'OpenID Connect verwijderd',
                        meta: 'OAuth Deleted'
                    }
                });
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'OpenID Connect verwijderen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['saveCredential']({ commit }, payload: Record<string, string>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `credentials/${payload.name}`,
                data: {
                    value: payload.value,
                    environment: payload.environment
                }
            }).then(() => {
                if (payload.value) {
                    commit('notify', {
                        type: 'success',
                        options: {
                            title: 'Wachtwoord / Token aangemaakt',
                            meta: 'Credential Created'
                        }
                    });
                }
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Wachtwoord / Token aanmaken mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['editCredential']({ commit }, payload: Record<string, string>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'PUT',
                url: `credentials/${payload.previousName}`,
                data: {
                    name: payload.name,
                    environment: payload.environment,
                    value: payload.value
                }
            }).then(() => {
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Wachtwoord / Token aangepast',
                        meta: 'Credential Edited'
                    }
                });
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Wachtwoord / Token aanpassen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['removeCredential']({ commit }, credential: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'DELETE',
                url: `credentials/${credential}`
            }).then(() => {
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Wachtwoord / Token verwijderd',
                        meta: 'Credential Deleted'
                    }
                });
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Wachtwoord / Token verwijderen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['testCredential']({ commit }, payload: Record<string, string>): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `credentials/${payload.name}/test`,
                data: {
                    value: payload.value,
                    environment: payload.environment
                }
            }).then(({ data: metainfo }) => {
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Token getest',
                        description: () => h('div', [
                            h('b', `${metainfo.info.appName}`),
                            h('br'),
                            h('span', 'GetConnectoren:'),
                            h('br'),
                            h('div', { style: { padding: '0 25px'}}, [
                                h('span', `${metainfo.getConnectors?.map((x: any) => `"${x.id}"`).join(`, `) || "Geen"}`)
                            ]),
                            h('span', 'UpdateConnectoren:'),
                            h('br'),
                            h('div', { style: { padding: '0 25px'}}, [
                                h('span', `${metainfo.updateConnectors?.map((x: any) => `"${x.id}"`).join(`, `) || "Geen"}`)
                            ]),
                        ]),
                        meta: 'Credential Tested'
                    }
                });
                resolve(metainfo);
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Token testen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getAllFlows']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'flows'
            }).then((res) => {
                commit('setAllFlows', res.data);
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getAllManualStartedLogs']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'logs/manual',
            }).then((res) => {
                commit('setAllManualStartedLogs', res.data);
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getManualStartedLogsThisMonth']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'logs/manualthismonth',
            }).then((res) => {
                commit('setManualStartedLogsThisMonth', res.data);
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['runManualFlow']({ commit }, options: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `manualrun`,
                data: {
                    flow: options.flow,
                    type: options.type
                }
            }).then((res) => {
                resolve(res.data);
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Succesvol ingepland',
                        meta: 'Manual Run Scheduled'
                    }
                });
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Plan opslaan mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getSubscription']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'organizations/subscription'
            }).then((res) => {
                commit('setSubscription', res.data);
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['removeFlow']({ commit }, flow: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'DELETE',
                url: `flows/${flow}`
            }).then(() => {
                resolve();
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Flow verwijderd',
                        meta: 'Flow Deleted'
                    }
                });
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Flow verwijderen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['duplicateFlow']({ commit }, flow: Record<string, any>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `flows/${flow.id}/duplicate`,
                data: {
                    name: flow.name
                }
            }).then(() => {
                resolve();
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Flow gedupliceerd',
                        meta: 'Flow Duplicated'
                    }
                });
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Flow dupliceren mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['saveSchedule']({ commit }, schedule: Record<string, any>): Promise<string> {
        const payload = JSON.parse(JSON.stringify(schedule));
        delete payload.flow;
        return new Promise<string>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `schedules/${schedule.flow}`,
                data: payload
            }).then((res) => {
                resolve(res.data);
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Plan opgeslagen',
                        meta: 'Schedule Saved'
                    }
                });
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Plan opslaan mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['removeSchedule']({ commit }, schedule: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'DELETE',
                url: `schedules/${schedule}`
            }).then(() => {
                resolve();
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Plan verwijderd',
                        meta: 'Schedule Deleted'
                    }
                });
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Plan verwijderen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getStatisticsForMonth']({ commit }, { year, month }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            instance({
                method: 'GET',
                url: `statistics?year=${year}&month=${month}`
            }).then((res) => {
                commit('setStatistics', res.data);
                resolve();
            }).catch((e) => {
                reject(e);
            });
        });
    },
    ['getLogs']({ commit }, obj: Record<string, any>): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            commit('setLoading', true);
            let url = `flows/${obj.flow}/logs?errorsOnly=${obj.errorsOnly}&updatesOnly=${obj.updatesOnly}`;
            if (obj.skip){
                url += `&skip=${obj.skip}`;
            }
            instance({
                method: 'GET',
                url: url
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getAllEmails']({ commit }): Promise<Array<string> | boolean> {
        return new Promise<Array<string> | boolean>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'emails'
            }).then((res) => {
                commit('setAllEmails', res.data);
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['saveEmail']({ commit }, payload: Record<string, string>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `emails`,
                data: {
                    email: payload.email
                }
            }).then(() => {
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Verificatie code verstuurd',
                        meta: 'Verficiation Code Send'
                    }
                });
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Verificatie code versturen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['verifyEmail']({ commit }, payload: Record<string, string>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `emails/verify`,
                data: {
                    email: payload.email,
                    verificationCode: payload.verificationCode
                }
            }).then(() => {
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Email geverifieerd',
                        meta: 'Email Verified'
                    }
                });
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Email verifieren mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['removeEmail']({ commit }, email: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'DELETE',
                url: `emails/${email}`
            }).then(() => {
                resolve();
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Email verwijderd',
                        meta: 'Email Deleted'
                    }
                });
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Email verwijderen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getAllEndpoints']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'endpoints'
            }).then((res) => {
                commit('setAllEndpoints', res.data);
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['requestEndpoint']({ commit }, flow: string): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: 'endpoints',
                data: { flow }
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Endpoint aanmaken mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['removeEndpoint']({ commit }, flow: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'DELETE',
                url: 'endpoints',
                data: { flow }
            }).then(() => {
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Endpoint verwijderen mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
}